import React from 'react';

import SelfiePlaceholder from '../../assets/images/logo/PlaceHolderImage.png';

const AboutUsPage = () => {
    return (
        <div className="text-center aboutusPage container px-3 py-5">
            <div className="row mx-auto mb-5">
                <div className="col-12">
                    <h1>What is Null Frontier?</h1>
                    <p>
                        Null Frontier is a indie software company specializing in developing and publishing video games.
                        With an ambition to craft immersive and entertaining games that explore and push the boundaries within various genres,
                        we stand on the edge of the unknown, where innovation meets creativity.
                    </p>
                </div>
            </div>

            <hr className="my-5" />

            <div className="row mx-auto mb-5 text-center aboutusPage-Team">
                <h1 className="col-12 mb-4">The Team</h1>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Oliver Hansen" />
                    <h2>Oliver Hansen</h2>
                    <h3 className="fs-6">Co-Founder & Head Programmer</h3>
                    <p>Experienced software developer with a background in Computer Science.  </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Noah Sørensen" />
                    <h2>Noah Sørensen</h2>
                    <h3 className="fs-6">Co-Founder & CG Artist</h3>
                    <p>Skilled creative CG artist with a passion for art.</p>
                </div>
            </div>

            <div className="row mx-auto mb-5 text-center aboutusPage-Team">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Frederick Dufour" />
                    <h2>Frederick Dufour</h2>
                    <h3 className="fs-6">Co-Founder & CG Artist</h3>
                    <p>Talented technical CG artist with a passion for art, history, and myths from the past;.</p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Florent Mustafaj" />
                    <h2>Florent Mustafaj</h2>
                    <h3 className="fs-6">Co-Founder & Future Astrophysicist</h3>
                    <p>Brings precision and depth to our games through advanced mathematics, physics, and analytical work.</p>
                </div>
            </div>

            <hr className="my-5" />

            <div className="row mx-auto aboutusPage-Statement">
                <h1>Statements</h1>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h2 className="fw-bold">Mission</h2>
                    <p>
                        Our mission is to develop video games that not only bring joy and entertainment to our customers
                        but also push the boundaries within various genres.
                        We achieve this by exploring new possibilities in game development and introducing fresh perspectives, as well as our unique spin within the genres.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h2 className="fw-bold">Vision</h2>
                    <p>
                        Our Vision is to thrive in the gaming industry as a smaller,
                        but recognized player,
                        while pursuing our dream of developing high-quality video games that reflect our passion and ambitions
                    </p>
                </div>
            </div>

            <div className="row mx-auto aboutusPage-Statement">
                <h2 className="fw-bold mb-4 fs-2">Values</h2>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold fs-3">Ambitious</h3>
                    <p>
                        We are driven by our desire to grow, improve, and continually push the boundaries of what’s possible in game development.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold fs-3">Integrity</h3>
                    <p>
                        We maintain transparency and honesty in all our endeavors,
                        ensuring that we stay true to our mission and our team, as well as our players.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold fs-3">Innovation</h3>
                    <p>
                        We embrace creativity and originality,
                        constantly seeking new ways to shake up the gaming experiences.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold fs-3">Quality</h3>
                    <p>
                        We are committed to delivering games of high quality,
                        with a deep focus on detail to ensure an enjoyable experience for our players.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold fs-3">Fun</h3>
                    <p>
                        We believe that games should bring joy not only to players but also to the creators.
                        The excitement and enjoyment players experience in our games is part of what drives our passion and inspires us to keep creating.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold fs-3">Tenacity</h3>
                    <p>
                        We persevere through challenges and setbacks,
                        always striving to achieve our goals and fulfill our vision.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default AboutUsPage;
