import React from 'react';
import BlogPostCard from '../blog/BlogPostCard';
import { useFetchBlogPostCardQuery } from '../../store/apis/blogsApi';
import { format } from 'date-fns';

const BlogPostPageCard = () => {
    const { data: postCard, error, isFetching } = useFetchBlogPostCardQuery();

    let content;
    if (isFetching) {
        content = <div>Loading...</div>;
    } else if (error) {
        content = <div>Error: {error.message}</div>;
    } else if (!postCard || !postCard.records) {
        content = <div>No posts found.</div>;
    } else {
        content = (
            <div>
                {postCard.records.map((post, index) => (
                    <React.Fragment key={index}>
                        <div className="full-post-card">
                            <BlogPostCard
                                id={post.id}
                                title={post.title}
                                date={format(new Date(post.xata.createdAt), 'MMMM dd, yyyy')}
                                description={post.description}
                                image={post.image[0].url}
                                type={post.type}
                                tags={post.tags}
                                blogs={post.blogs}
                            />
                        </div>
                        {/*makes a line between each post except the last one*/}
                        {index < postCard.records.length - 1 && <hr className="w-100 my-4" />}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    return (
        <div className="container blog-page">
            {content}
        </div>
    );
};
export default BlogPostPageCard;

